<template>
  <el-header>
    <el-menu default-active="1" class="el-menu-demo" mode="horizontal"
    background-color="rgb(31,78,121)"
    text-color="#fff"
    router
    active-text-color="#fff">
      <span class="logo">Multimorbidity</span>
      <el-menu-item index="/home">Home</el-menu-item>
      <el-menu-item index="/browse">Browse</el-menu-item>
      <el-menu-item index="/search">Search</el-menu-item>
      <el-menu-item index="/downloads">Downloads</el-menu-item>
      <el-menu-item index="/help">Help</el-menu-item>
    </el-menu>
  </el-header>
</template>

<script>
export default {};
</script>

<style scoped>
  .el-menu--horizontal > .el-menu-item {
    font-size: 18px;
  }
.el-menu-item:hover{
    background-color: #fff;
}
.logo{
    height: 61px;
    line-height: 61px;
    color: #fff;
    float: left;
    margin-left: 80px;
    margin-right: 80px;
    font-size: 35px;
    text-shadow: 0 0 3px #dddddd;
}
.el-header{
  padding: 0;
}
  .menu-item-box{
  }
</style>
