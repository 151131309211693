<template>
  <el-container class="container">
    <my-nav></my-nav>
    <el-main>
        <router-view></router-view>
    </el-main>
    <el-footer>
      <span>Copyright@2020
        <a href="http://www.comp-sysbio.org" target="_blank">Zhao Group, Institute of Science and Technology
          for Brain-inspired Intelligence (ISTBI)</a>
        , Fudan University, China
        </span>
    </el-footer>
  </el-container>
</template>
<script>
import mynav from "../components/common/nav";
export default {
  components: {
    "my-nav": mynav,
  },
};
</script>
<style lang="less">
.el-main {
  padding: 0;
  ul,li {
    list-style: none;
  }
  .el-breadcrumb{
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #e1e1e1;
  padding-left: 80px;
  box-shadow: 1px 1px 3px #dddddd;
}
}
.container {
  height: 100vh;
}
.el-footer {
  background-color: #ccc;
  padding:20px;
  font-size: 14px;
  a{
    text-decoration: none;
    outline: 0;
    color: #0092ca;
    transition: color .3s;
  }
  a:hover{
    color: #48b2ce;
  }
}
</style>